import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class CommonValidators {
  static validUri: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.hasValidator(Validators.required) && !control.value) {
      return null;
    }

    if (control.value && !(control.value as string).startsWith('http')) {
      return { startsWith: false };
    }

    try {
      new URL(control.value);
      return null;
    } catch (_) {
      return { validUri: false };
    }
  };

  static validEmail: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.includes('@')) {
      return null;
    }
    return { validEmail: false };
  };

  static startsWith(shouldStartWith: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && (control.value as string).startsWith(shouldStartWith)) {
        return null;
      }
      return { startsWith: { requiredToStartWith: shouldStartWith } };
    };
  }

  static notEndWith(shouldNotEndWith: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && !(control.value as string).endsWith(shouldNotEndWith)) {
        return null;
      }
      return { notEndsWith: { requiredToNotEndWith: shouldNotEndWith } };
    };
  }

  static hasNoLeadingOrTrailingWhiteSpaces: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>
    control.value?.trim() === control.value ? null : { noLeadingOrTrailingWhiteSpaces: false };

  static equals(shouldBeEqualTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === shouldBeEqualTo) {
        return null;
      }
      return { notEqual: { requiredToBeEqualTo: shouldBeEqualTo } };
    };
  }
}
